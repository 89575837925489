// Node Imports
import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { useIntl } from 'gatsby-plugin-intl';

// Components to be rendered on every page
import GlobalStyles from '../../styles/globalStyles';
import Header from '../Header';
import Footer from '../Footer';
import theme from '../../themes/theme';
import BackToTop from '../BackToTop';

// ====== STYLING ======
const StyledLayout = styled.div`
  color: ${props => props.theme.PrimaryColour_2};
  padding-top: 4.6rem;
`;

const Layout = ({ header, footer, notificationBanners, children }) => {
  const intl = useIntl();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <StyledLayout>
        <Header data={header} notificationBanners={notificationBanners} />
        <main id="mainContent">{children}</main>
        <Footer data={footer} />
      </StyledLayout>
      <BackToTop locale={intl.locale} />
    </ThemeProvider>
  );
};

// ====== PROP TYPES ======
Layout.propTypes = {
  header: PropTypes.shape().isRequired,
  footer: PropTypes.shape().isRequired,
  notificationBanners: PropTypes.arrayOf(PropTypes.shape()),
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  notificationBanners: [],
};

export default Layout;
