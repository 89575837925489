import styled from 'styled-components';
import notificationBannerBackground from '../../images/notification-banner-background.svg';

export const StyledBanner = styled.div`
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0.5rem 1rem 0.5rem 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  text-align: center;
  background-color: ${({ color, theme }) =>
    theme.colors[color] || theme.colors.warning};
  background-image: url('${notificationBannerBackground}');
  background-position: center center;
  background-repeat: repeat;

  @media only screen and (max-width: 600px) {
    padding-left: 1rem;
  }
`;

export const BannerContent = styled.div`
  margin: 0 auto;
  text-align: center;

  p {
    font-size: 1rem;
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
  }

  strong,
  b {
    font-weight: 600;
  }
`;

export const CloseButton = styled.button`
  font-size: 2rem;
  line-height: 1;
  padding: 0rem 1.5rem;

  @media only screen and (max-width: 600px) {
    padding-right: 0.5rem;
  }
`;
