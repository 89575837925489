import React from 'react';
import PropTypes from 'prop-types';
import ScrollUpButton from 'react-scroll-up-button';

import arrow from '../../images/back-to-top.svg';

import { BackToTopWrapper, Image } from './styles';

const BackToTop = ({ locale }) => {
  const LANG_BACKUP = locale === 'en' ? 'Back up' : 'Remonter';
  return (
    <BackToTopWrapper>
      <ScrollUpButton
        ContainerClassName="buttonTop"
        TransitionClassName="buttonTopAnimate"
        ShowAtPosition={600}
        AnimationDuration={1000}
      >
        <div>
          <Image src={arrow} alt="Back to top" role="presentation" />
          <p>{LANG_BACKUP}</p>
        </div>
      </ScrollUpButton>
    </BackToTopWrapper>
  );
};

BackToTop.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default BackToTop;
