import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';

const StyledLink = styled(Link)`
  text-align: center;
  text-decoration: none;

  /* Hamburger menu links */
  &.hamburger {
    font-weight: 800;
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }

  &.skipToMainLink {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    &:focus {
      opacity: 1;
    }
  }

  &.navigation {
    padding: 10px 16px 9px;
    border: 1px solid white;
    border-radius: 24px;
    transition: box-shadow 300ms, color 300ms, border-color 100ms;

    &[aria-current] {
      background-color: ${({ theme }) => theme.colors.red};
      color: white;
      -webkit-font-smoothing: antialiased;
    }
  }

  &.red {
    color: ${({ theme }) => theme.colors.plum};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.colors.blueberry};
    }
  }

  &.white {
    box-shadow: ${({ theme }) => theme.dropShadow.primary};

    &:hover,
    &:focus {
      box-shadow: ${({ theme }) => theme.dropShadow.hover};
      border: 1px solid white;
      color: inherit;
    }
  }

  &.pink {
    &:hover:not([aria-current]),
    &:focus:not([aria-current]) {
      color: ${({ theme }) => theme.colors.primaryRed};
      border-color: ${({ theme }) => theme.colors.primaryRed};
    }
  }
`;

// {children} renders within the button as text
// {customStyle} is an array of classes
// {url} is the URL to link to
// {route} is whether to link to an internal page or external page
const TextLink = ({ children, customStyle, url, activeClassName }) => {
  const isInternal = /^\/(?!\/)/.test(url);
  const isSpecialLink = /\btel:\b|\bmailto:\b|#\w+/.test(url);
  const secureSiteUrl = /secure.pcinsiders/.test(url);
  const ariaLabelContent = isSpecialLink
    ? null
    : `To ${url}. Link will open in a new window.`;

  const handleLinkProps = ({ isCurrent, isPartiallyCurrent }) => {
    // eslint-disable-next-line no-useless-computed-key
    return isPartiallyCurrent || isCurrent ? { ['aria-current']: 'page' } : {};
  };

  return isInternal ? (
    <StyledLink
      activeClassName={activeClassName}
      className={customStyle}
      to={url}
      getProps={handleLinkProps}
    >
      {children}
    </StyledLink>
  ) : (
    <StyledLink
      as="a"
      className={customStyle}
      href={url}
      target={isSpecialLink ? '' : '_blank'}
      rel={isSpecialLink || secureSiteUrl ? '' : 'noopener noreferrer'}
      aria-label={ariaLabelContent}
      activeClassName={activeClassName}
    >
      {children}
    </StyledLink>
  );
};

// Prop types
TextLink.propTypes = {
  children: PropTypes.node,
  customStyle: PropTypes.arrayOf(PropTypes.string),
  url: PropTypes.string,
  activeClassName: PropTypes.string,
};

// Default props
TextLink.defaultProps = {
  customStyle: [''],
  children: "I'm a link",
  url: '',
  activeClassName: '',
};

export default TextLink;
