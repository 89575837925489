import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import * as Contentful from '@contentful/rich-text-react-renderer';
import { useIntl } from 'gatsby-plugin-intl';

import TextLink from '../UI/TextLink';
import ButtonLink from '../UI/ButtonLink';

const ContentfulText = ({ document }) => {
  const { locale } = useIntl();
  const lang = `${locale}-CA`;
  const defaultLang = 'en-CA';

  const getLocalizedFields = (fields, children) => {
    const localizeFields = field =>
      Object.entries(field).reduce((acc, [key, val]) => {
        const localizedValues = val[lang] || val[defaultLang];
        if (localizedValues) {
          const value = localizedValues.fields
            ? localizeFields(localizedValues.fields)
            : localizedValues;
          return {
            ...acc,
            [key]: value,
          };
        }
        return acc;
      }, {});

    const entry = localizeFields(fields);
    if (entry.asset) {
      return <img src={entry.asset.url} alt={entry.altText || ''} />;
    }
    switch (entry.linkFrontEndFormat) {
      case 'Text-Link':
        return (
          <TextLink customStyle={[entry.style]} url={entry.linkUrl}>
            {children || entry.linkText}
          </TextLink>
        );
      case 'Button':
        return (
          <ButtonLink
            route="external"
            customStyle={[entry.style]}
            url={entry.linkUrl}
          >
            {entry.icon ? (
              <img src={entry.icon.asset.url} alt={entry.icon.altText || ''} />
            ) : (
              entry.linkText
            )}
          </ButtonLink>
        );
      default:
        return null;
    }
  };

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.CODE]: html => ReactHtmlParser(html),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, text) => <p>{text}</p>,
      [BLOCKS.UL_LIST]: (node, text) => <ul>{text}</ul>,
      [BLOCKS.LIST_ITEM]: (node, text) => <li>{text}</li>,
      [BLOCKS.EMBEDDED_ENTRY]: ({ data }) =>
        getLocalizedFields(data.target.fields),
      [INLINES.EMBEDDED_ENTRY]: ({ data }) =>
        getLocalizedFields(data.target.fields),
      [INLINES.ENTRY_HYPERLINK]: ({ data }, children) =>
        getLocalizedFields(data.target.fields, children),
    },
  };
  return Contentful.documentToReactComponents(document, options);
};

export default ContentfulText;
