import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { animated } from 'react-spring';
import styled from 'styled-components';

import TextLink from '../../../UI/TextLink';

export const StyledMenu = styled(animated.div)`
  z-index: 500;
  background: ${({ theme }) => theme.colors.white};
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  font-size: 1rem;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  transform: translateY(${({ open }) => (open ? '0' : '-100%')});
  transition: transform 300ms ease-out;

  @media only screen and (min-width: 1023px) {
    display: none;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;

  @supports (-webkit-touch-callout: none) {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }

  hr {
    margin: 0 20px;
    color: ${({ theme }) => theme.colors.secondaryGrey};
    opacity: 0.1;
  }

  ul {
    display: ${({ open }) => (open ? 'flex' : 'none')};
    color: ${({ theme }) => theme.colors.secondaryGrey};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 56px;

    li {
      margin-top: 24px;
      font-size: 1.5rem;

      @media ${({ theme }) => theme.breakpoints.tablet} {
        font-size: 2.625rem;
        margin-top: 64px;
      }

      a {
        &:hover,
        &:focus {
          color: ${({ theme }) => theme.colors.primaryRed};
        }
      }
    }
  }
`;

const FixedMenu = ({
  setMobileMenuOpen,
  mobileMenuOpen,
  headerPageLinks,
  headerButtons,
}) => {
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleKeyDown = e => {
    const focusableElements = document.querySelectorAll(
      '.burgerMenu, .fixedMenuNavLinks a'
    );

    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement =
      focusableElements[focusableElements.length - 1];

    if (e.code === 'Escape') {
      const burgerMenu = document.querySelector('.burgerMenu');
      setMobileMenuOpen(false);
      e.stopPropagation();
      burgerMenu.focus();
    }

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        e.preventDefault();
      }
    } else if (
      e.key !== 'Enter' &&
      e.keyCode !== 13 &&
      document.activeElement === lastFocusableElement
    ) {
      firstFocusableElement.focus();
      e.preventDefault();
    }
  };

  return (
    <StyledMenu open={mobileMenuOpen}>
      <Navigation open={mobileMenuOpen}>
        <ul className="fixedMenuNavLinks">
          {headerPageLinks.concat(headerButtons).map(link => (
            <li key={link.id}>
              <TextLink route="internal" url={link.linkUrl}>
                <strong>{link.linkText}</strong>
              </TextLink>
            </li>
          ))}
        </ul>
      </Navigation>
    </StyledMenu>
  );
};

FixedMenu.propTypes = {
  mobileMenuOpen: PropTypes.bool.isRequired,
  setMobileMenuOpen: PropTypes.func.isRequired,
  headerPageLinks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  headerButtons: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default FixedMenu;
