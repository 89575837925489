/* eslint-disable prefer-destructuring */
// Default themes to be used on initial load, or if no other theme loads
// Imported into <ThemeGetter />
const theme = {
  breakpoints: {
    tablet: `(min-width: 768px)`,
    desktop: `(min-width: 1024px)`,
    screenWidths: ['374px', '767px', '1023px'],
  },
  dropShadow: {
    primary:
      '0 1px 5px rgba(158, 147, 166, 0.2), 0 3px 4px rgba(158, 147, 166, 0.12), 0 2px 4px rgba(158, 147, 166, 0.14)',
    hover:
      '0 4px 5px rgba(158, 147, 166, 0.2), 0 3px 14px rgba(158, 147, 166, 0.12), 0 8px 10px rgba(158, 147, 166, 0.14)',
    pageSection:
      '0px 8px 10px rgba(158, 147, 166, 0.14), 0px 3px 14px rgba(158, 147, 166, 0.12), 0px 4px 5px rgba(158, 147, 166, 0.2)',
  },
  colors: {
    FontColour_1: '#1a1e26',
    FontColour_2: '#778a9c',
    FontColour_3: '#ffffff',
    PrimaryColour_1: '#cd4802',
    PrimaryColour_2: '#ff5800',
    PrimaryColour_3: '#ff925d',
    PrimaryColour_4: '#c1d5d4',
    PrimaryColour_5: '#eff5f5',
    PrimaryColour_6: '#ffffff',
    white: '#fff',
    quoteGray: '#f5f5f5',
    quoteDarkGray: '#ecedee',
    gray: '#e8e9eb',
    lightGray: '#f0f0f0',
    mediumGray: '#9ea2a7',
    deepGray: '#626262',
    footerGray: '#2b2c32',
    dullGray: '#37383d',
    darkGray: '#2c2a29',
    oldRed: '#DA1E10',
    oldRedHover: '#BA1E0E',
    red: '#E82011',
    'Phoenix Red': '#E82011',
    darkRed: '#d43d32',
    darkestRed: '#df3127',
    redOnGray: '#d92a20',
    redOnYellow: '#ba241c',
    lightRed: '#f3746d',
    offwhitePink: '#fefbfb', // TODO: chage!! -- EXTREEME accessibility issue
    lightestPink: '#fff9f9', // TODO: change -- EXTREME accessibility issue
    lightPink: '#fff5f4', // TODO: change ^
    pink: '#fbe7e5',
    darkSalmon: '#feeceb',
    salmon: '#fadcd9',
    lightBiege: '#f8f4f3',
    // $alternative-pink: #f67f79, // background change to match hero video
    alternativePink: '#f2736b', // background change to match hero video
    navyBlue: '#2e2e3a',

    // spinner
    spinnerDarkPink: '#fa837c',
    spinnerLightPink: '#fed9d8',
    spinnerDarkRed: '#982822',
    spinnerRed: '#c5372e',
    info: '#b4d7e9',
    warning: '#f0ebbc',
    success: '#bde3b9',
    error: '#ecbfbd',

    // PCI Redesign
    primaryGrey: '#6B6272',
    primaryGrey2: '#fcfcfc',
    secondaryGrey: '#403840',
    secondaryGrey2: '#6B6272',
    secondaryGrey3: '#9E93A6',
    secondaryGrey4: '#f4f1f6',
    primaryBlack: '#191919',
    primaryPink: '#f86457',
    primaryPink2: '#fbf1f1',
    primaryRed: '#DA291C',
    plum: '#6C221A',
    blueberry: '#2D2E39',
  },
  fonts: {
    noir: ['Noir Pro', 'Arial Black', 'Arial', 'sans-serif'],
    inter: ['Inter', 'sans-serif'].join(','),
  },
  fontSizes: {
    mobileCallOut: '1.125rem',
    mobileLink: '0.875rem',
    mobileFooterParagraph: '0.75rem',
    mobileParagraph: '0.8125rem',
    tabletCallOut: '1.375rem',
    desktopParagraph: '0.9375rem',
    desktopSubscriptionHeader: '1.5rem',
    desktopCallOut: '2rem',
    desktopHeader: '4rem',
  },
  lineHeights: {
    mobileFooterParagraph: '1rem',
    mobileParagraph: '1.3125rem',
    tabletCallOut: '1.75rem',
    desktopParagraph: '1.4375rem',
    desktopCallOut: '2.9375rem',
  },
};

theme.breakpoints.sm = theme.breakpoints.screenWidths[0];
theme.breakpoints.md = theme.breakpoints.screenWidths[1];
theme.breakpoints.lg = theme.breakpoints.screenWidths[2];

// export default theme;
export default theme;
