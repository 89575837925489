import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Burger from './Burger';
import Logo from '../../UI/Logo';

const Nav = styled.div`
  font-size: 1rem;
  height: 4.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: ${({ open }) =>
    !open
      ? '0px 2px 4px rgba(158, 147, 166, 0.14), 0px 3px 4px rgba(158, 147, 166, 0.12), 0px 1px 5px rgba(158, 147, 166, 0.2)'
      : 'unset'};

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .header {
    flex-grow: 1;
    text-align: center;
    margin-right: 30px;

    img {
      max-width: 114px;
    }
  }

  @media only screen and (min-width: 1023px) {
    display: none;
  }
`;

const MobileNav = ({ mobileMenuOpen, setMobileMenuOpen, logo, title }) => (
  <Nav open={mobileMenuOpen}>
    <Burger open={mobileMenuOpen} setOpen={setMobileMenuOpen} />
    <Logo
      tabIndex={mobileMenuOpen ? '-1' : '0'}
      logo={logo}
      customStyle={['header']}
      logoLink="/"
      ariaLabelForLink={title}
    />
  </Nav>
);

MobileNav.propTypes = {
  mobileMenuOpen: PropTypes.bool.isRequired,
  setMobileMenuOpen: PropTypes.func.isRequired,
  logo: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
};

export default MobileNav;
