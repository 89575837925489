import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';

import GatsbyImage from '../../GatsbyImage';

// Style options
export const StyledLogo = styled.div`
  &.small {
    width: 30px;
    height: auto;
  }
  &.medium {
    width: 50px;
    height: auto;
  }
  &.large {
    width: 100px;
    height: auto;
  }

  &.header {
    display: block;
    flex-basis: 195px;

    @media ${({ theme }) => theme.breakpoints.desktop} {
    }
  }

  img {
    width: 100%;
    height: auto;
  }
`;

// { logoSrc } is the path to the image file to be used
// { logoImageAlt } is the alt description for the image
// {logoLink} - optional. If a URL is passed, will be wrapped in <a> and be an external href.
// { customStyle } is an array of classes
const Logo = ({
  logo,
  logoSrc,
  alt,
  logoLink,
  customStyle,
  ariaLabelForLink,
  tabIndex,
}) => {
  return !logoLink ? (
    <StyledLogo className={[customStyle, 'logo']}>
      <GatsbyImage localFile={logoSrc.localFile} alt={alt} />
    </StyledLogo>
  ) : (
    <StyledLogo className={[customStyle, 'logo']}>
      <Link
        tabIndex={tabIndex}
        className="logo"
        to={logoLink}
        aria-label={ariaLabelForLink}
      >
        {logo && <img src={logo.asset.url} alt={logo.altText || ''} />}
        {logoSrc && <GatsbyImage localFile={logoSrc.localFile} alt={alt} />}
      </Link>
    </StyledLogo>
  );
};

// Prop types
Logo.propTypes = {
  logoSrc: PropTypes.shape(),
  alt: PropTypes.string,
  logoLink: PropTypes.string,
  customStyle: PropTypes.arrayOf(PropTypes.string),
  ariaLabelForLink: PropTypes.string,
  logo: PropTypes.shape(),
  tabIndex: PropTypes.string,
};

// Default props
Logo.defaultProps = {
  logoSrc: null,
  logo: null,
  alt: 'PC Insiders logo',
  logoLink: '/',
  customStyle: ['medium'],
  ariaLabelForLink: '',
  tabIndex: '',
};

export default Logo;
