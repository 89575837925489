/* eslint no-unused-vars: "off" */
import React from 'react';
import htmlToReactParser from 'html-to-react';
import PropTypes from 'prop-types';
import assign from 'lodash.assign';
import uniqueSlug from 'unique-slug';
import { useIntl } from 'gatsby-plugin-intl';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MARKS } from '@contentful/rich-text-types';

export { all, fragment, legal } from './types';

const RichText = ({ document, options, spend, benefit }) => {
  const intl = useIntl;
  if (!document) return null;
  const reactParser = new htmlToReactParser.Parser();
  const config = typeof options === 'function' ? options() : options;
  // * All Special chars to search for ~ Must have regex exp & component
  const specialChars = {
    br: {
      regex: '\n',
      component: () => <br key={uniqueSlug()} />,
    },
    spend: {
      regex: '(CURRENT_INPUT_VALUE)',
      component: `${spend}`,
    },
    mc: {
      regex: 'ᵐᶜ',
      component: () => <sup key={uniqueSlug()}>MC</sup>,
    },
    benefit: {
      regex: '(CURRENT_OUTPUT_VALUE)',
      component: <span className="red" key={benefit}>{`${benefit}`}</span>,
    },
  };

  // * Custom parse function that replaces matched special chars with components
  // * Chunks input values (Either string, obj, bool, or array of types) and recursively checks them for special chars that match those listed in the specialChars obj, replaces matched chars with their 'component'
  // * reactParser.parse() parses html tags on the final string
  const parse = (value, expressions, iterator) => {
    let increment = iterator;
    const text = typeof value === 'object' ? value : [value];
    const expr = Object.values(expressions);
    const regxp = expr[increment];
    if (!text.length) return text;

    return text.flatMap(val => {
      if (!val) return '';
      let string = val;

      string =
        typeof string !== 'string'
          ? string
          : string.split(regxp.regex).flatMap((subText, i) => {
              const component =
                typeof regxp.component === 'function'
                  ? regxp.component()
                  : regxp.component;

              return [i > 0 && component, reactParser.parse(subText)];
            });
      if (increment + 1 < expr.length) {
        increment += 1;
        return parse(string, specialChars, increment);
      }

      return string;
    });
  };

  // * Custom Parser option for Contentful RichText
  const parser = {
    renderText: text => parse(text, specialChars, 0),
  };

  // * Default Marks resolvers
  const defaultConfig = {
    renderMark: {
      [MARKS.BOLD]: text => <b key={uniqueSlug()}>{text}</b>,
      [MARKS.ITALIC]: text => <i key={uniqueSlug()}>{text}</i>,
    },
  };

  // * Components after parsing
  const parsedComponents = documentToReactComponents(
    document,
    assign(defaultConfig, config, parser)
  );

  return <>{parsedComponents}</>;
};

RichText.propTypes = {
  document: PropTypes.shape().isRequired,
  options: PropTypes.shape(),
  spend: PropTypes.string,
  benefit: PropTypes.string,
};

RichText.defaultProps = {
  options: {},
  spend: null,
  benefit: null,
};

export default RichText;
