import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 */

const GatsbyImage = ({ localFile, alt, height, width, maxWidth }) => {
  const image = localFile.childImageSharp || null;

  if (image) {
    return (
      <Img
        fluid={image.fluid}
        style={{
          height: height || '100%',
          maxWidth: maxWidth || '100%',
          width: width || image.fluid.presentationWidth,
        }}
        alt={alt}
        durationFadeIn={250}
      />
    );
  }
  return <img src={localFile.publicURL} alt={alt} />;
};

GatsbyImage.propTypes = {
  localFile: PropTypes.shape().isRequired,
  alt: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
};

GatsbyImage.defaultProps = {
  alt: '',
  width: null,
  height: null,
  maxWidth: null,
};

export default GatsbyImage;
