import styled from 'styled-components';

export const BackToTopWrapper = styled.div`
  .buttonTop {
    position: fixed;
    right: -6rem;
    bottom: 25%;
    border-radius: 0.3rem 0 0 0.3rem;
    background-color: ${({ theme }) => theme.colors.white};
    transition: all 0.5s;
    cursor: pointer;
    padding: 8px 12px;
    box-shadow: 0px 8px 10px rgba(158, 147, 166, 0.14),
      0px 3px 14px rgba(158, 147, 166, 0.12),
      0px 4px 5px rgba(158, 147, 166, 0.2);

    @media screen and (max-width: 768px) {
      bottom: 5%;
    }

    &:hover {
      box-shadow: 0px 16px 24px rgba(158, 147, 166, 0.14),
        0px 6px 30px rgba(158, 147, 166, 0.12),
        0px 8px 10px rgba(158, 147, 166, 0.3);

      & > * {
        opacity: 50%;
      }
    }

    div {
      text-align: center;
    }

    p {
      color: ${({ theme }) => theme.colors.secondaryGrey};
      font-size: 1rem;
      transition: all 0.5s;
      margin: 0;

      @media screen and (max-width: 768px) {
        font-size: 0.8rem;
      }
    }
  }
  .buttonTopAnimate {
    right: 0;
  }
`;

export const Image = styled.img`
  margin: 22px auto;
  max-width: 35px;
`;
