/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, location, noIndex }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const path = location ? location.href : '';
  const otherLang = lang === 'en' ? 'fr' : 'en';
  const otherPath = path ? path.replace(`/${lang}/`, `/${otherLang}/`) : '';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        {
          rel: 'alternate',
          href: path,
          hreflang: `${lang}-ca`,
        },
        {
          rel: 'alternate',
          href: otherPath,
          hreflang: `${otherLang}-ca`,
        },
        {
          rel: 'canonical',
          href: path || process.env.BASE_URL,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(meta)
        .concat(noIndex ? { name: 'robots', content: 'noindex' } : {})}
    />
  );
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  location: PropTypes.shape(),
  noIndex: PropTypes.bool,
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  location: {},
  noIndex: false,
};

export default SEO;
