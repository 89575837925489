// NODE IMPORTS
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Container from '../Container';
import LanguageToggle from '../LanguageToggle';
import Text from '../ContentfulText';

const FooterContainer = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.colors.secondaryGrey3};
  color: ${({ theme }) => theme.colors.secondaryGrey};

  @media ${({ theme }) => theme.breakpoints.tablet} {
    border-top: 0;
  }
`;

const FooterInner = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryGrey3};
  margin-top: 24px;
  padding-bottom: 24px;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
  }

  li:not(:first-of-type) {
    font-family: ${({ theme }) => theme.fonts.inter};
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 64px;
    padding-bottom: 32px;
  }

  p:empty {
    display: none;
  }

  h2 {
    font-size: 0.9375rem;
    line-height: ${({ theme }) => theme.lineHeights.mobileParagraph};

    @media ${({ theme }) => theme.breakpoints.tablet} {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`;

const PhoneNumberListContainer = styled.div`
  margin: 16px 0 24px;
  font-size: ${({ theme }) => theme.fontSizes.mobileLink};
  line-height: 1.125rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes.desktopParagraph};
    line-height: ${({ theme }) => theme.lineHeights.desktopParagraph};
    width: 145px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 280px;
  }

  li {
    margin: 12px 0 0;
  }

  img {
    width: 142px;
    height: auto;
    margin-bottom: 4px;
  }
`;

const LinksListContainer = styled.div`
  @media ${({ theme }) => theme.breakpoints.tablet} {
    width: 155px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 195px;
  }

  li {
    margin-bottom: 12px;
  }

  a {
    font-size: ${({ theme }) => theme.fontSizes.mobileFooterParagraph};
    line-height: ${({ theme }) => theme.lineHeights.mobileFooterParagraph};
    text-decoration: underline;

    @media ${({ theme }) => theme.breakpoints.tablet} {
      font-size: ${({ theme }) => theme.fontSizes.desktopParagraph};
      line-height: ${({ theme }) => theme.lineHeights.desktopParagraph};
    }
  }
`;

const DownloadAppListContainer = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.secondaryGrey};
    font-weight: bold;
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes.mobileFooterParagraph};
    line-height: ${({ theme }) => theme.lineHeights.mobileFooterParagraph};
    color: ${({ theme }) => theme.colors.secondaryGrey2};

    @media ${({ theme }) => theme.breakpoints.tablet} {
      font-size: ${({ theme }) => theme.fontSizes.desktopParagraph};
      line-height: ${({ theme }) => theme.lineHeights.desktopParagraph};
    }
  }

  ul {
    max-width: 280px;

    &:nth-child(2) {
      display: flex;

      @media ${({ theme }) => theme.breakpoints.tablet} {
        flex-direction: column;
      }

      @media ${({ theme }) => theme.breakpoints.desktop} {
        flex-direction: row;
      }

      li {
        margin: 0;

        &:first-child {
          margin-right: 32px;

          @media ${({ theme }) => theme.breakpoints.tablet} {
            margin: 0 0 16px 0;
          }

          @media ${({ theme }) => theme.breakpoints.desktop} {
            margin: 0 32px 0 0;
          }
        }
      }
    }

    @media ${({ theme }) => theme.breakpoints.tablet} {
      max-width: 210px;
    }

    @media ${({ theme }) => theme.breakpoints.desktop} {
      max-width: 302px;
    }
  }
`;

const FooterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    flex-direction: column;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row;
  }
`;

const FooterButtons = styled.a`
  display: inline-block;
  padding: 0;

  &:first-of-type {
    margin-right: 32px;

    @media ${({ theme }) => theme.breakpoints.tablet} {
      margin-bottom: 16px;
    }

    @media ${({ theme }) => theme.breakpoints.desktop} {
      margin-bottom: 0;
    }
  }

  img {
    width: 110px;
    height: auto;

    @media ${({ theme }) => theme.breakpoints.tablet} {
      width: 135px;
    }
  }
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    padding: 24px 0 40px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 24px 0 60px;
  }
`;

const TrademarkText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.mobileFooterParagraph};
  line-height: ${({ theme }) => theme.lineHeights.mobileFooterParagraph};
  margin: 0;
  max-width: 176px;
  font-family: ${({ theme }) => theme.fonts.inter};

  @media ${({ theme }) => theme.breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.desktopParagraph};
    line-height: ${({ theme }) => theme.lineHeights.desktopParagraph};
    max-width: 364px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-width: 800px;
  }

  p {
    margin: 0;
  }
`;

const Footer = ({ data }) => {
  const {
    phoneNumberList,
    linksList,
    downloadAppList,
    trademark,
    footerLogo,
    appleStoreLink,
    googlePlayLink,
  } = data;

  return (
    <FooterContainer>
      <Container maintainWidth>
        <FooterInner>
          <PhoneNumberListContainer>
            <img src={footerLogo.asset.url} alt={footerLogo.altText} />{' '}
            <Text document={phoneNumberList.json} />
          </PhoneNumberListContainer>
          <LinksListContainer>
            <Text document={linksList.json} />
          </LinksListContainer>
          <DownloadAppListContainer>
            <Text document={downloadAppList.json} />
            <FooterButtonContainer>
              <FooterButtons
                href={appleStoreLink.linkUrl}
                rel="noopener noreferrer"
                target="_blank"
                aria-label={appleStoreLink.linkText}
              >
                <img
                  src={appleStoreLink.icon.asset.url}
                  alt={appleStoreLink.icon.altText}
                />
              </FooterButtons>
              <FooterButtons
                href={googlePlayLink.linkUrl}
                rel="noopener noreferrer"
                target="_blank"
                aria-label={googlePlayLink.linkText}
              >
                <img
                  src={googlePlayLink.icon.asset.url}
                  alt={googlePlayLink.icon.altText}
                />
              </FooterButtons>
            </FooterButtonContainer>
          </DownloadAppListContainer>
        </FooterInner>
        <FooterBottom>
          <TrademarkText>
            <Text document={trademark.json} />
          </TrademarkText>
          <LanguageToggle toggleStyle="footer" />
        </FooterBottom>
      </Container>
    </FooterContainer>
  );
};

Footer.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Footer;
