import styled from 'styled-components';

export const Sticky = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 999;
`;

export const StyledHeader = styled.header`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
    font-size: 0.92rem;
  }

  li {
    display: flex;
    flex-direction: column;
    list-style: none;
    text-align: center;
    align-items: center;
    padding: 0;
    margin: 0 0.55em;

    @media screen and (max-width: 440px) {
      margin: 0 1vw;
    }

    a {
      margin: 0;

      @media screen and (max-width: 440px) {
        font-size: 3.5vw;
      }
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 4.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 1rem;

  @media only screen and (max-width: 767px) {
    display: none;
    visibility: hidden;
  }
`;
