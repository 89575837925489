import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import MobileNav from './MobileNav';
import FixedMenu from './MobileNav/FixedMenu';
import DesktopNav from './DesktopNav';
import { Sticky, StyledHeader } from './styles';
import NotificationBanners from '../NotificationBanners';

const Header = ({ data, notificationBanners }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const intl = useIntl();
  const header = data;

  const handleMobileNavClick = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  useEffect(() => {
    const html = document.querySelector('html');
    const body = document.querySelector('body');

    if (mobileMenuOpen) {
      disableBodyScroll(body);
      html.style.overflow = 'hidden';
    } else {
      enableBodyScroll(body);
      html.style.overflow = 'unset';
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [mobileMenuOpen]);

  const { headerButtons, headerPageLinks, icon } = header;
  const title =
    intl.locale === 'en'
      ? 'PC Optimum Insiders Subscription'
      : 'Abonnement au programme Adeptes PC Optimum';

  return (
    <>
      <Sticky>
        {!mobileMenuOpen && <NotificationBanners data={notificationBanners} />}
        <StyledHeader>
          <DesktopNav
            headerPageLinks={headerPageLinks}
            headerButtons={headerButtons}
            logo={icon}
            title={title}
          />
          <MobileNav
            mobileMenuOpen={mobileMenuOpen}
            setMobileMenuOpen={handleMobileNavClick}
            logo={icon}
            title={title}
          />
        </StyledHeader>
      </Sticky>
      <FixedMenu
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        headerPageLinks={headerPageLinks}
        headerButtons={headerButtons}
      />
    </>
  );
};

Header.propTypes = {
  data: PropTypes.shape().isRequired,
  notificationBanners: PropTypes.arrayOf(PropTypes.shape()),
};

Header.defaultProps = {
  notificationBanners: [],
};

export default Header;
