// * This file is used to define all the different options available to our RichText Component
// * More info available here https://www.npmjs.com/package/@contentful/rich-text-react-renderer

import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

// * Resolves all BLOCKS into their respected HTML tags
export const all = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <p>{children}</p>,
    [BLOCKS.HEADING_1]: (_, children) => <h1>{children}</h1>,
    [BLOCKS.HEADING_2]: (_, children) => <h2>{children}</h2>,
    [BLOCKS.HEADING_3]: (_, children) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (_, children) => <h4>{children}</h4>,
    [BLOCKS.HEADING_5]: (_, children) => <h5>{children}</h5>,
    [BLOCKS.HEADING_6]: (_, children) => <h6>{children}</h6>,
    [BLOCKS.OL_LIST]: (_, children) => <ol>{children}</ol>,
    [BLOCKS.UL_LIST]: (_, children) => <ul>{children}</ul>,
    [BLOCKS.LIST_ITEM]: (_, children) => <li>{children}</li>,
  },
};

// * Resolves all P tags into React fragments
export const fragment = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <>{children}</>,
  },
};

// * Resolves P tags into a legal text style
export const legal = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <p className="tiny">{children}</p>,
  },
};
