import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { IntlContextConsumer } from 'gatsby-plugin-intl';

const StackedToggle = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.plum};
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.mobileLink};
  line-height: 1.5rem;
  text-transform: uppercase;

  span {
    font-weight: bold;
  }
`;

const LocaleLink = styled(Link)`
  opacity: 0.7;
`;

const Divider = styled.span`
  margin: 0 4px;
`;

const LanguageToggle = () => {
  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale, originalPath }) => {
        return (
          <StackedToggle>
            {languages.map((language, index) => (
              <React.Fragment key={`H-${language}`}>
                {language === currentLocale && <span>{language}</span>}
                {language !== currentLocale && (
                  <LocaleLink
                    to={`/${language}${originalPath}`}
                    aria-label={
                      language === 'en'
                        ? 'Changer la langue en anglais'
                        : 'Change language to French.'
                    }
                  >
                    {language}
                  </LocaleLink>
                )}
                {index < languages.length - 1 && <Divider>•</Divider>}
              </React.Fragment>
            ))}
          </StackedToggle>
        );
      }}
    </IntlContextConsumer>
  );
};

export default LanguageToggle;
