import { createGlobalStyle } from 'styled-components';

// Noir Pro
import NoirProRegular from '../fonts/noirpro-regular.otf';
// Noir Pro Medium
import NoirProMedium from '../fonts/noirpro-medium.otf';
// Noir Pro Bold
import NoirProBold from '../fonts/noirpro-bold.otf';

const fonts = (...urls) => urls.map(url => `url('${url}')`).join(', ');

const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: 'Noir Pro';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: ${fonts(NoirProRegular)};
}

@font-face {
  font-family: 'Noir Pro';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: ${fonts(NoirProMedium)};
}

@font-face {
  font-family: 'Noir Pro';
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: ${fonts(NoirProBold)};
}

/* Reset box-model
   ========================================================================== */

* {
  box-sizing: border-box;
}

::before,
::after {
  box-sizing: inherit;
}

/* Reset ul list style bug
       ========================================================================== */

ul {
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}

/* Document
       ========================================================================== */

/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in iOS.
     * 3. Remove gray overlay on links for iOS.
     */

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -webkit-tap-highlight-color: transparent; /* 3*/
  /* margin-left: calc(100vw - 100%);
    margin-right: 0; */

  &.blade-is-open {
    /* margin-left: -15px; */
    overflow: hidden;
    /* margin-left: calc(100vw - 100%); */
  }
}

/* Sections
       ========================================================================== */

/**
     * Remove the margin in all browsers.
     */

body {
  margin: 0;

  /* &.blade-is-open {
    overflow: hidden;
  } */
}

/**
     * Render the 'main' element consistently in IE.
     */

main {
  display: block;
}

/* Vertical rhythm
       ========================================================================== */

table,
blockquote,
address,
pre,
iframe,
form,
figure,
dl {
  margin: 0;
}

/* Headings
       ========================================================================== */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  font-weight: 600;
}

p sup {
  font-family: ${({ theme }) => theme.fonts.inter};
}

/* Lists (enumeration)
       ========================================================================== */

ul,
ol {
  margin: 0;
  padding: 0;
}

/* Lists (definition)
       ========================================================================== */

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

/* Grouping content
       ========================================================================== */

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
  border: 0;
  border-top: 1px solid;
  margin: 0;
  clear: both;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd 'em' font sizing in all browsers.
     */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: inherit; /* 2 */
}

address {
  font-style: inherit;
}

/* Text-level semantics
       ========================================================================== */

/**
     * Remove the gray background on active links in IE 10.
     */

a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}

/**
     * 1. Remove the bottom border in Chrome 57-
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */

b,
strong {
  font-weight: 600;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd 'em' font sizing in all browsers.
     */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: inherit; /* 2 */
}

/**
     * Add the correct font size in all browsers.
     */

small {
  font-size: 80%;
}

/**
     * Prevent 'sub' and 'sup' elements from affecting the line height in
     * all browsers.
     */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */

/**
     * Remove the border on images inside links in IE 10.
     */

img {
  border-style: none;
  vertical-align: bottom;
}

embed,
object,
iframe {
  border: 0;
  vertical-align: bottom;
}

/* Forms
       ========================================================================== */

button,
input,
optgroup,
select,
textarea {
  vertical-align: middle;
  color: inherit;
  font: inherit;
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
}

/**
     * Remove all Style fro select elements. Does not quite work cross-browser
     */

select {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}

/**
     * Show the overflow in IE.
     * 1. Show the overflow in Edge.
     */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * Correct the inability to style clickable types in iOS and Safari.
     */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}

button[disabled],
[type='button'][disabled],
[type='reset'][disabled],
[type='submit'][disabled] {
  cursor: default;
}

/**
     * Remove the inner border and padding in Firefox.
     */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
     * Restore the focus styles unset by the previous rule.
     */

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Reset to invisible
     */

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 0;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from 'fieldset' elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    'fieldset' elements in all browsers.
     */

legend {
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
     * Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */

progress {
  vertical-align: baseline;
}

/**
     * Remove the default vertical scrollbar in IE 10+.
     */

textarea {
  overflow: auto;
}

/**
     * 1. Add the correct box sizing in IE 10.
     * 2. Remove the padding in IE 10.
     */

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
     * Remove the inner padding in Chrome and Safari on macOS.
     */

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to 'inherit' in Safari.
     */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
     * Fix placeholder font properties inheritance.
     */

::-webkit-input-placeholder {
  font: inherit;
}

:-ms-input-placeholder {
  font: inherit;
}

::-ms-input-placeholder {
  font: inherit;
}

::placeholder {
  font: inherit;
}

/**
     * Clickable labels
     */

label[for] {
  cursor: pointer;
}

/* Interactive
       ========================================================================== */

/*
     * Add the correct display in Edge, IE 10+, and Firefox.
     */

details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */

summary {
  display: list-item;
}

/* Table
       ========================================================================== */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption {
  text-align: left;
}

td,
th {
  vertical-align: top;
}

th {
  text-align: left;
}

/* Misc
       ========================================================================== */

/**
     * Add the correct display in IE 10+.
     */

template {
  display: none;
}

/**
     * Add the correct display in IE 10.
     */

[hidden] {
  display: none;
}

`;

export default GlobalStyles;
