import styled, { css } from 'styled-components';

export const LeftGutter = css`
  padding-left: 7.5vw;

  @media screen and (max-width: 1600px) {
    padding-left: 6vw;
  }

  @media screen and (max-width: 1200px) {
    padding-left: 4.5vw;
  }

  @media screen and (max-width: 640px) {
    padding-left: 1rem;
  }
`;

export const RightGutter = css`
  padding-right: 7.5vw;

  @media screen and (max-width: 1600px) {
    padding-right: 6vw;
  }

  @media screen and (max-width: 1200px) {
    padding-right: 4.5vw;
  }

  @media screen and (max-width: 640px) {
    padding-right: 1rem;
  }
`;

const Container = styled.div`
  @media screen and (min-width: 1441px) {
    margin: 0 auto;
    max-width: ${({ maintainWidth }) => (maintainWidth ? 'none' : '1200px')};
  }

  ${LeftGutter};
  ${RightGutter};
`;

export default Container;
