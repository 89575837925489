// NODE IMPORTS
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';

export const StyledButton = styled(Link)`
  font-weight: 400;
  margin: 2px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  min-width: 4em;
  padding: 0.75em 1em 0.8em;
  transition: color 250ms ease-out, background-color 250ms ease-out,
    border-color 250ms ease-out;
  word-break: keep-all;
  white-space: nowrap;
  display: inline-block;

  /* Button Styles */
  &.solid {
    background-color: ${({ theme }) => theme.colors.PrimaryColour_1};
    color: ${({ theme }) => theme.colors.FontColour_3};
  }
  &.outline {
    padding: 10px 25px;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.FontColour_1};
    border: solid 1px ${({ theme }) => theme.colors.PrimaryColour_1};
  }
  &.White {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.secondaryGrey};
    border: solid 1px ${({ theme }) => theme.colors.secondaryGrey};
  }
  &.White:hover,
  &.White:focus {
    color: ${({ theme }) => theme.colors.plum};
    border: solid 1px ${({ theme }) => theme.colors.plum};
  }

  &.Red {
    background-color: ${({ theme }) => theme.colors.oldRed};
    border: solid 1px ${({ theme }) => theme.colors.oldRed};
    color: ${({ theme }) => theme.colors.white};
  }
  &.Red:hover {
    background-color: ${({ theme }) => theme.colors.oldRedHover};
    border-color: ${({ theme }) => theme.colors.oldRedHover};
  }

  /* Button Sizes */
  &.medium {
    padding: 12px 30px;
  }
  &.large {
    padding: 16px 40px;
  }

  /* Button Corners */
  &.pill {
    border-radius: 25px;
  }
  &.rectangle {
    border-radius: 0;
  }

  &.default {
    border-radius: 4px;
    color: white;
    border: 1px solid ${({ theme }) => theme.colors.primaryBlack};
    background-color: ${({ theme }) => theme.colors.primaryBlack};
    -webkit-font-smoothing: antialiased;

    &:hover,
    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.plum};
      background-color: ${({ theme }) => theme.colors.plum};
    }
  }

  &.dark {
    border-radius: 4px;
    color: white;
    border: 1px solid white;
    background-color: ${({ theme }) => theme.colors.blueberry};

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.blueberry};
      background-color: white;
    }
  }

  &.navigation {
    padding: 10px 16px 9px;
    border: 1px solid white;
    border-radius: 24px;
    transition: box-shadow 300ms, color 300ms, border-color 100ms;
    display: block;
  }

  &.white {
    box-shadow: ${({ theme }) => theme.dropShadow.primary};

    &:hover,
    &:focus {
      box-shadow: ${({ theme }) => theme.dropShadow.hover};
      border: 1px solid white;
      color: inherit;
    }
  }

  &.pink {
    &:hover:not(.headerActive),
    &:focus:not(.headerActive) {
      color: ${({ theme }) => theme.colors.primaryPink};
      border-color: ${({ theme }) => theme.colors.primaryPink};
    }
  }
`;

// {children} renders within the button as text
// {customStyle} is an array of classes
// {route} specifies whether the link will be internal or external
// {url} is the address to link to
const ButtonLink = ({ children, customStyle, route, url, ariaLabel }) => {
  const secureSiteUrl = /secure.pcinsiders/.test(url);

  return route === 'internal' ? (
    <StyledButton className={customStyle} to={url} aria-label={ariaLabel}>
      {children}
    </StyledButton>
  ) : (
    <StyledButton
      as="a"
      className={customStyle}
      href={url}
      target="_blank"
      rel={secureSiteUrl ? '' : 'noopener noreferrer'}
      aria-label={ariaLabel}
    >
      {children}
    </StyledButton>
  );
};

// Prop types
ButtonLink.propTypes = {
  customStyle: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  url: PropTypes.string,
  route: PropTypes.string,
  ariaLabel: PropTypes.string,
};

// Default props
ButtonLink.defaultProps = {
  customStyle: ['solid', 'medium', 'rectangle'],
  children: 'Button',
  route: 'internal',
  url: '',
  ariaLabel: '',
};

export default ButtonLink;
