import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ButtonLink from '../../UI/ButtonLink';
import TextLink from '../../UI/TextLink';
import Logo from '../../UI/Logo';

const Navigation = styled.div`
  height: 4.6875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(158, 147, 166, 0.1);

  .logo {
    flex-shrink: 0;
  }

  @media only screen and (max-width: 1023px) {
    display: none;
  }

  ul {
    a {
      font-weight: 600;
    }
  }
`;

const HeaderButtonsContainer = styled.div`
  margin-left: 95px; 
`

const DesktopNav = ({ headerPageLinks, headerButtons, logo, title }) => (
  <Navigation>
    <Logo
      logo={logo}
      customStyle={['header']}
      logoLink="/"
      ariaLabelForLink={title}
    />
    <ul>
      {headerPageLinks.map(link => (
        <li key={link.id}>
          <TextLink
            customStyle={[link.style, 'pill', 'navigation']}
            route="internal"
            url={link.linkUrl}
            activeClassName="active"
          >
            {link.linkText}
          </TextLink>
        </li>
      ))}
    </ul>
    <HeaderButtonsContainer>
      <ul>
      {headerButtons.map(button => (
        <li key={button.id}>
          <ButtonLink
            route="external"
            url={button.linkUrl}
            customStyle={[button.style, 'pill', 'navigation']}
          >
            {button.linkText}
          </ButtonLink>
        </li>
      ))}
      </ul>
    </HeaderButtonsContainer>
  </Navigation>
);

DesktopNav.propTypes = {
  headerPageLinks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  headerButtons: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  logo: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
};

export default DesktopNav;
