import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import RichText from '../RichText';

import { StyledBanner, BannerContent, CloseButton } from './styles.js';

// Notification banner that displays
// at the top of all pages
const NotificationBanners = ({ data }) => {
  let shownOnAllPages = useStaticQuery(query);
  shownOnAllPages = shownOnAllPages.allContentfulNotificationBanner.edges.map(
    ({ node }) => node
  );

  const banners = shownOnAllPages.concat(data || []);
  const [currentBanner, setCurrentBanner] = useState(getCurrentBanner(banners));

  // If there are no eligible
  // banners, don't render
  if (!currentBanner) return null;

  const {
    id,
    isDismissible,
    color,
    bannerContent,
    bannerCount,
  } = currentBanner;

  // When the user clicks the close button,
  // show the next eligible banner
  const closeCurrentBanner = () => {
    currentBanner.seen = true;
    const timesSeen = parseInt(
      localStorage.getItem(`NotificationBanner-${id}`) || '0',
      10
    );
    // If the current banner has a count
    // greater than 0 (always show), set
    // a flag in localstorage with the amount
    // of times it's been seen
    if (isCountable(bannerCount))
      localStorage.setItem(`NotificationBanner-${id}`, timesSeen + 1);
    setCurrentBanner(getCurrentBanner(banners));
  };

  return (
    <StyledBanner color={color}>
      <BannerContent>
        <RichText document={bannerContent.json} />
      </BannerContent>
      {isDismissible && (
        <CloseButton type="button" onClick={closeCurrentBanner}>
          &times;
        </CloseButton>
      )}
    </StyledBanner>
  );
};

NotificationBanners.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};

NotificationBanners.defaultProps = {
  data: [],
};

// Is the bannerCount a valid countable number
const isCountable = bannerCount =>
  bannerCount && bannerCount !== '0' && bannerCount !== 'always show';

// Find the first eligible banner to show
const getCurrentBanner = banners =>
  banners &&
  banners
    // Sort non-dismissable banners to the end
    .sort(({ isDismissible: a }, { isDismissible: b }) => b - a)
    // Remove duplicates
    .filter(
      ({ id }, index) => banners.findIndex(banner => id === banner.id) === index
    )
    // Find the first banner that hasn't already been seen
    .find(({ seen, id, bannerCount }) => {
      if (seen) return false;
      if (!isCountable(bannerCount)) return true;
      return localStorage.getItem(`NotificationBanner-${id}`) < bannerCount;
    });

const query = graphql`
  query NotificationBannerQuery($locale: String = "en-CA") {
    allContentfulNotificationBanner(
      filter: { showOnAllPages: { eq: true }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          isDismissible
          color
          bannerCount
          bannerContent {
            json
          }
        }
      }
    }
  }
`;

export default NotificationBanners;
